/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
}

html, body { 
  height: 100%; 
}

body { 
  margin: 0; 
  font-weight: 400;
  font-size: 15px;
}

.par-400 {
  font-weight:400;
}

.par-600 {
  font-weight:600;
}

a {
  color: #061f38;
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

li{list-style:none;}

h1 {   
  font-size: 2.0rem;
  color: inherit;
  font-weight: normal;
}

p {
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin-bottom: 0;
}

.btn {
  background-color: #a0d3ff;
  text-align: center;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 20%);
  color: #062d50;
  border-radius: 8px;
  line-height: 1.2;
}

@media only screen and (max-width: 768px){
  .btn {
    font-size:14px;
  }
}

.flex-center {
  display:flex;
  justify-content: center;
}

.spacer {
  flex: 1;
}

.left-divider-sm {
  background: #4f9797;
  border-radius: 8px;
  display: block;
  height: 8px;
  width: 50px;
  margin: 12px 0;
}

.left-divider-smst {
  background: #4f9797;
  border-radius: 8px;
  display: block;
  height: 3px;
  width: 50px;
  margin: 12px 0;
}

.font-lightblue {
  color: #115997;
}

.container-outter {
  width: 100%;
  margin:80px 0px 0px 0px;
    @media only screen and (max-width: 768px) {
      margin:20px 0px 0px 0px;
    }
}

.container {
  max-width:1300px;
  margin:0 auto;
  padding:0px 15px;
}

.container-inner {
  display: flex;
  flex-wrap: wrap;
}

.space-bottom {
  padding-bottom:110px;
}

.space-top {
  margin-top:30px;
}

.heading-section {
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:center;
  margin: 110px 0px 70px 0px;
  @media only screen and (max-width: 768px) {
    margin:50px 0px 20px 0px;
  }
}

.heading-title {
  text-align:center;
  h1 {
    font-size:31px;
    font-weight:bold;
      @media only screen and (max-width: 768px){
        font-size:25px;
        padding:0 20px;
        margin:0 auto;
      }
  }
  p, a {
    font-weight:600;
    font-size:22px;
    margin:15px 0;
    line-height:1.7;
    @media only screen and (max-width: 768px){
      font-size:18px;
      padding:10px 20px;
      margin:0 auto;
    }
  }
}

.first-p {
  margin-top: 15px!important;
}

.section-title {
  text-align:center;
  h1 {
    font-size:31px;
    font-weight:bold;
      @media only screen and (max-width: 768px){
        font-size:25px;
        padding:0 20px;
        margin:0 auto;
      }
  }
  p {
    font-weight:600;
    font-size:18px;
    margin:5px 0;
    line-height:1.7;
    @media only screen and (max-width: 768px){
      padding:5px 20px;
      margin:0 auto;
    }
  }
}

/* material css changes */

.data-container {
  justify-content:space-between;
  padding:1rem;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #061f38;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color:#061f38;
}

.mat-card-subtitle, .mat-card-content {
  font-size: 15px;
}

.mat-input-element {
  caret-color: #061f38;
}

.mat-form-field-infix {
  border-top: 1.84375em solid transparent;
}

.mat-tab-label-content {text-transform:uppercase;}